export const config = {
    DEBUG: process.env.STAGE === "local",
    AUTHORIZATION_HEADER: process.env.AUTHORIZATION_HEADER || "X-Authorization",
    STAGE: process.env.STAGE as "local" | "dev" | "prod" | "pre-prod" | "pov-dev" | "sandbox",
    FRONTEND_URL: process.env.NEXT_PUBLIC_FRONTEND_URL,
    GTM_ID: process.env.NEXT_PUBLIC_GTM_ID,
    FACEBOOK_APP_ID: process.env.FACEBOOK_APP_ID,
    NODE_ENV: process.env.NODE_ENV,
    PRIVACY_POLICY_URL: "https://www.predplatko.cz/privacy.html",
    TERMS_AND_CONDITIONS_URL: "https://www.predplatko.cz/terms.html",
};
