// this file is generated automatically, do not change anything manually in the contents of this file

export const ICONS = {
    "arrow-left": { w: 448, h: 512 },
    "arrow-right": { w: 448, h: 512 },
    "calendar": { w: 448, h: 512 },
    "camera": { w: 512, h: 512 },
    "caretDown": { w: 320, h: 512 },
    "clock": { w: 512, h: 512 },
    "bars": { w: 448, h: 512 },
    "check": { w: 448, h: 512 },
    "chevronDown": { w: 512, h: 512 },
    "chevronLeft": { w: 384, h: 512 },
    "chevronsLeft": { w: 512, h: 512 },
    "chevronRight": { w: 384, h: 512 },
    "chevronsRight": { w: 512, h: 512 },
    "chevronUp": { w: 512, h: 512 },
    "cloud": { w: 640, h: 512 },
    "copy": { w: 512, h: 512 },
    "ellipsis-vertical": { w: 128, h: 512 },
    "file": { w: 384, h: 512 },
    "imageFile": { w: 384, h: 512 },
    "triangle-exclamation": { w: 512, h: 512 },
    "user": { w: 448, h: 512 },
    "videoFile": { w: 384, h: 512 },
    "xmark": { w: 320, h: 512 },
    "xmarkLarge": { w: 448, h: 512 },
    "arrow-right-to-bracket": { w: 512, h: 512 },
    "circle-info": { w: 512, h: 512 },
    "circle-xmark": { w: 512, h: 512 },
    "clock-rotate-left": { w: 512, h: 512 },
    "qrcode": { w: 448, h: 512 },
    "location-dot": { w: 384, h: 512 },
    "lock-keyhole": { w: 448, h: 512 },
    "magnifying-glass": { w: 512, h: 512 },
    "minus": { w: 448, h: 512 },
    "plate-utensils": { w: 640, h: 512 },
    "plus": { w: 448, h: 512 },
    "receipt": { w: 384, h: 512 },
    "star": { w: 576, h: 512 },
    "star-solid": { w: 576, h: 512 },
    "trash-can-xmark": { w: 448, h: 512 },
} as const;

export type IconsType = keyof typeof ICONS;

declare module "@uxf/ui/icon/theme" {
    interface IconsSet {
        "arrow-left": true;
        "arrow-right": true;
        "calendar": true;
        "camera": true;
        "caretDown": true;
        "clock": true;
        "bars": true;
        "check": true;
        "chevronDown": true;
        "chevronLeft": true;
        "chevronsLeft": true;
        "chevronRight": true;
        "chevronsRight": true;
        "chevronUp": true;
        "cloud": true;
        "copy": true;
        "ellipsis-vertical": true;
        "file": true;
        "imageFile": true;
        "triangle-exclamation": true;
        "user": true;
        "videoFile": true;
        "xmark": true;
        "xmarkLarge": true;
        "arrow-right-to-bracket": true;
        "circle-info": true;
        "circle-xmark": true;
        "clock-rotate-left": true;
        "qrcode": true;
        "location-dot": true;
        "lock-keyhole": true;
        "magnifying-glass": true;
        "minus": true;
        "plate-utensils": true;
        "plus": true;
        "receipt": true;
        "star": true;
        "star-solid": true;
        "trash-can-xmark": true;
    }
}
