import { Cookie } from "@uxf/core/cookie";
import { Button } from "@uxf/ui/button";
import { closeModal, openModal } from "@uxf/ui/modal/modal-service";
import dayjs from "dayjs";
import { useEffect } from "react";

export const SERVER_REDIRECTED_TO_TOMORROW_COOKIE_NAME = "server-redirected-to-tomorrow";

export const useHandleRedirectToTomorrow = () => {
    const cookie = Cookie.create();
    const redirectedFromDate = cookie.get(SERVER_REDIRECTED_TO_TOMORROW_COOKIE_NAME);

    useEffect(() => {
        if (redirectedFromDate) {
            openModal({
                children: (
                    <>
                        <div className="px-4 pb-4 pt-6 text-center">
                            <p className="uxf-typo-h6 mb-2">
                                Na {dayjs(redirectedFromDate).format("l")} už nelze objednat.
                            </p>
                            <p className="uxf-typo-h6 mb-2">Předplaťte si oběd na zítra.</p>
                        </div>
                        <div className="bg-gray-50 px-4 py-3">
                            <Button isFullWidth onClick={closeModal}>
                                Rozumím
                            </Button>
                        </div>
                    </>
                ),
                width: "sm",
            });
            cookie.delete(SERVER_REDIRECTED_TO_TOMORROW_COOKIE_NAME);
        }
    }, [cookie, redirectedFromDate]);
};
