import { ApolloProvider } from "@apollo/client";
import { UxfNextPage } from "@app-routes";
import { config } from "@config/config";
import { ICONS } from "@config/icons";
import { ICONS_VERSION, ICON_SPRITE } from "@config/icons-config";
import { twScreens } from "@config/tw-tokens/tw-screens";
import { LocalizeProvider } from "@localize";
import { Barlow, Barlow_Condensed } from "@next/font/google";
import { useHandleRedirectToTomorrow } from "@shared/hooks/use-handle-redirect-to-tomorrow";
import { useApollo } from "@shared/lib/Apollo/apollo-client";
import { LastLocationProvider, useUpdateLastLocation } from "@shared/lib/last-location/last-location";
import "@ui/styles/globals.css";
import { COLOR_SCHEME_DEFAULTS } from "@uxf/ui/color-scheme";
import { UiContextProvider, UiContextType } from "@uxf/ui/context";
import { FlashMessages } from "@uxf/ui/flash-messages/flash-messages";
import { getFlashMessagesRef } from "@uxf/ui/flash-messages/flash-messages-service";
import { Modal } from "@uxf/ui/modal/modal";
import { getModalRef } from "@uxf/ui/modal/modal-service";
import { locale } from "dayjs";
import "dayjs/locale/cs";
import { AppProps } from "next/app";
import Head from "next/head";

locale("cs");

const UI_PROVIDER_CONFIG: UiContextType = {
    colorScheme: {
        defaultColorScheme: "light",
        getUserSettings: COLOR_SCHEME_DEFAULTS.getUserSettings,
        respectSystemSetting: false,
        respectUserSettings: false,
        setUserSettings: COLOR_SCHEME_DEFAULTS.setUserSettings,
    },
    icon: { spriteFilePath: `${ICON_SPRITE}?v=${ICONS_VERSION}`, iconsConfig: ICONS },
    rasterImage: { breakpoints: twScreens },
    domain: config.FRONTEND_URL + "",
};

const fontBarlow = Barlow({
    adjustFontFallback: true,
    preload: true,
    subsets: ["latin", "latin-ext"],
    variable: "--font-barlow",
    weight: ["400", "500", "600"],
    display: "swap",
});

const fontBarlowCondensed = Barlow_Condensed({
    adjustFontFallback: true,
    preload: true,
    subsets: ["latin", "latin-ext"],
    variable: "--font-barlow-condensed",
    weight: ["600"],
    display: "swap",
});

type AppPropsWithLayout = AppProps & {
    Component: UxfNextPage;
};

function UxfApp(props: AppPropsWithLayout) {
    const apollo = useApollo(props.pageProps);
    const lastLocation = useUpdateLastLocation(props.Component.noHistory, props.Component.protectPreviousHistory);

    useHandleRedirectToTomorrow();

    return (
        <>
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx global>
                {`
                    :root {
                        --font-barlow: ${fontBarlow.style.fontFamily};
                        --font-barlow-condensed: ${fontBarlowCondensed.style.fontFamily};
                    }
                `}
            </style>
            <Head>
                <meta charSet="utf-8" />
                <meta
                    name="viewport"
                    content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
                />
            </Head>
            <LocalizeProvider value="cs">
                <ApolloProvider client={apollo}>
                    <LastLocationProvider value={lastLocation}>
                        <UiContextProvider value={UI_PROVIDER_CONFIG}>
                            <props.Component {...props.pageProps} />
                            <FlashMessages ref={getFlashMessagesRef()} />
                            <Modal ref={getModalRef()} />
                        </UiContextProvider>
                    </LastLocationProvider>
                </ApolloProvider>
            </LocalizeProvider>
        </>
    );
}

export default UxfApp;
